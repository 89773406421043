<template>
  <div>
    <navbar url="/instrument" title="测试结果"></navbar>
    <div class="huge">
      {{ $route.query.flag == "true" ? "合格" : "不合格" }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang='scss' scoped>
.huge {
  text-align: center;
  margin-top: 0.5rem;
  font-size: 0.45rem;
}
</style>